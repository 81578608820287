import React, { useEffect, useState,useRef  } from 'react';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from "html2canvas";

import axios from 'axios';

const App = () => {


  const apiUrl = process.env.REACT_APP_BASE_URL;
  const invoiceRef = useRef();

 const [labelData, setLabelData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [itemDetails, setItemDetails] = useState([]); 
  const [charges, setCharges] = useState(null);
  const [orderId, setOrderid] = useState(null);
  const [returnAddress, setReturnAddress] = useState({
    companyName: "SnaXup",
    address: "SNAXUP HEALTHY FOODS INDIA PRIVATE LIMITED, Unit2, A123, DDA Sheds, Block A, Okhla Phase II, Okhla Industrial Estate, New Delhi, Delhi 110020",
    mobileNo: "+91 8929 446 677",
    gstNo: "07ABHCS9739H1ZR"
  });
  const parseItemsString = (itemsString) => {
    if (itemsString && typeof itemsString === 'string') {
      return itemsString.split(',').map(item => {
        const [id, title, quantity, size, price] = item.split(':');
        return {
          id: parseInt(id),
          title,
          quantity: parseInt(quantity),
          size,
          price: parseFloat(price)
        };
      });
    } else {
      console.warn("Items data is missing or in an incorrect format.");
      return [];
    }
  };
  useEffect(() => {
    // Get the 'orderId' from the URL
    const queryParams = new URLSearchParams(window.location.search);
    setOrderid(queryParams.get('orderId'))
    console.log("order",orderId)
    if (orderId) {
      // Fetch the order details using the orderId
      axios.get(`${apiUrl}orders/${orderId}`)
        .then(response => {
          const propertyList = response.data;
          // Convert items string to an array
          const itemsData = parseItemsString(propertyList.items);
          const itemDetailsArray = itemsData.map(item => ({
            sku: item.sku || item.id,
            itemName: item.title || item.title,
            qty: item.quantity,
            amount: item.price,
            size:item.size
          }));

          // Set data in state
          setInvoiceData({
            deliverTo: propertyList.fname,
            lname: propertyList.lname,
            address: propertyList.addressLine1,
            address1: propertyList.addressLine2,
            provience: propertyList.provience, 
            city: propertyList.city, 
            mobileNo: propertyList.phone,
            pincode: propertyList.postalCode,
            orderDate: propertyList.created_at,
            invoiceNo: propertyList.orderId,
            codAmount: propertyList.totalAmount,
            weight: propertyList.weight,
            dimensions: "10 x 10 x 10"
          });
          setItemDetails(itemDetailsArray);
          setCharges({
            shippingCharges:propertyList.paymentMethod === 'COD' ? '₹30' : 'FREE', // Conditional expression for shipping cost

            discountApplied: propertyList.discount || 0,
            orderTotal: propertyList.totalAmount
          });
        })
        .catch(error => {
          console.error('Error fetching order details:', error);
        });
    }
  }, [orderId]);



console.log("data",labelData)

  if (!labelData, !invoiceData, !itemDetails, !charges ) {
    return <p>Loading label data...</p>;
  }


  const generatePDF = async () => {
    const invoiceElement = invoiceRef.current;

    // Capture the invoice element as a canvas using html2canvas
    const canvas = await html2canvas(invoiceElement);
    const imageData = canvas.toDataURL("image/png");

    // Create a jsPDF instance and add the image to the PDF
    const pdf = new jsPDF("p", "pt", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Add the captured image to the PDF
    pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
    
    // Download the PDF
    pdf.save("invoice.pdf");
  };
  
  const download4x6PDF = async () => {
    const invoiceElement = invoiceRef.current;

    // Use html2canvas to capture the element
    const canvas = await html2canvas(invoiceElement, { scale: 3 });
    const imgData = canvas.toDataURL("image/png");

    // Create a new PDF in 4x6 inches
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [4, 6],
    });

    // Add the captured image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, 4, 6);

    // Save the PDF
    pdf.save("invoice_4x6.pdf");
  };

  console.log("data",invoiceData)

  
  return (
    <>
    
    <div ref={invoiceRef} className="invoice-container">
      {/* Delivery Info with Logo */}
      <div className="flex items-start mb-4">
        <img
          src="https://snaxup.com/cdn/shop/files/1200x628.jpg?v=1728038002&width=2048"
          alt="SnaXup Logo"
          className="h-10 mr-4"
          width="80"
          height="70"
        />
        <div>
          <p className="mb-1"><strong>Deliver To:</strong> {invoiceData.deliverTo} {invoiceData.lname}</p>
          <p className="mb-1"><strong>Address:</strong> {invoiceData.address}, {invoiceData.address1}</p>
          <p className="mb-1"><strong></strong> {invoiceData.city}, {invoiceData.provience}</p>

          <p className="mb-1"><strong>Mobile No:</strong> {invoiceData.mobileNo}</p>
          <p className="mb-1"><strong>Pincode:</strong> {invoiceData.pincode}</p>
        </div>
      </div>

      {/* Order Date, Invoice No and First Barcode */}
      <div className="flex justify-between items-center my-4">
        <div>
          <p><strong>Order Date:</strong> {invoiceData.orderDate}</p>
          <p><strong>Invoice No:</strong> SNX{invoiceData.invoiceNo}</p>
        </div>
        <div className="text-right" id="barcode1">
          <Barcode value={invoiceData.invoiceNo} width={1} height={50} format="CODE128" />
        </div>
      </div>

      {/* COD, Weight, Dimensions and Second Barcode */}
      <div className="flex justify-between items-center my-4">
        <div className="flex-1">
          <p className="text-2xl font-bold">COD</p>
          <p className="text-lg font-semibold">{invoiceData.codAmount}</p>
        </div>
        <div className="flex-1 text-right">
          <p><strong>Weight:</strong> {invoiceData.weight}kg</p>
          <p><strong>Dimensions (cm):</strong> {invoiceData.dimensions}</p>
          <div id="barcode2">
            <Barcode value={invoiceData.pincode} width={1} height={50} format="CODE128" />
          </div>
        </div>
      </div>

      {/* Item Details and Charges Table */}
      <div className="border-t pt-4">
        <table className="w-full text-left border-collapse border">
          <thead>
            <tr>
              <th className="border py-2 px-3">SKU</th>
              <th className="border py-2 px-3">Item Name</th>
              <th className="border py-2 px-3">Qty</th>
              <th className="border py-2 px-3">Amount</th>
            </tr>
          </thead>
          <tbody>
            {itemDetails.map((item, index) => (
              <tr key={index}>
                <td className="border py-2 px-3">{item.sku}</td>
                <td className="border py-2 px-3">{item.itemName}</td>
                <td className="border py-2 px-3">{item.qty}</td>
                <td className="border py-2 px-3">₹{item.amount}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="3" className="border py-2 px-3 text-right font-semibold">Shipping Charges</td>
              <td className="border py-2 px-3">{charges.shippingCharges}</td>
            </tr>
            <tr>
              <td colSpan="3" className="border py-2 px-3 text-right font-semibold">Discount Applied</td>
              <td className="border py-2 px-3">₹{charges.discountApplied}</td>
            </tr>
            <tr>
              <td colSpan="3" className="border py-2 px-3 text-right font-semibold">Order Total</td>
              <td className="border py-2 px-3">₹{charges.orderTotal}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Return Address */}
      <div className="mt-6">
        <p className="text-xl font-semibold">Return Address:</p>
        <p>{returnAddress.companyName}</p>
        <p>{returnAddress.address}</p>
        <p>{returnAddress.mobileNo}</p>
        <p>{returnAddress.gstNo}</p>
      </div>

   
    </div>
       {/* Button to Generate PDF */}
       <div className="text-center mt-8 flex flex-col items-center space-y-4">
  <button
    className="btn btn-dark mt-3 px-5 mb-3 rounded hover:bg-blue-600"
    onClick={generatePDF}
  >
    Generate Label
  </button>
  <button
    className="btn btn-dark mt-3 px-5 mb-3 rounded hover:bg-blue-600"
    onClick={download4x6PDF}
  >
    Generate 4x6 Invoice
  </button>
</div>

   
  </>
  );
};

export default App;
