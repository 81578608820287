import React, { useState, useRef, useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from 'axios';


const MultiLabelPrinter = () => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const labelRefs = useRef([]);
  const [orders, setOrders] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
          const fetchProperties = async () => {
            try {  
              const response = await axios.get(`${apiUrl}Allorders`);
              setOrders(response.data);
            } catch (error) {
              console.error('Error fetching properties data:', error);
            }
          };
          fetchProperties();
        }, []);

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const downloadMultipleLabels = async () => {
    if (selectedOrders.length === 0) {
      alert("Please select at least one order to print.");
      return;
    }

    // Group selected orders into pages of 4
    const pages = [];
    for (let i = 0; i < selectedOrders.length; i += 4) {
      pages.push(selectedOrders.slice(i, i + 4));
    }

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [4, 6],
    });

    for (let i = 0; i < pages.length; i++) {
      const pageOrders = pages[i];

      // Capture labels for the current page
      for (let j = 0; j < pageOrders.length; j++) {
        const labelElement = labelRefs.current[pageOrders[j]];
        const canvas = await html2canvas(labelElement, { scale: 3 });
        const imgData = canvas.toDataURL("image/png");

        // Calculate position for each label (2x2 grid)
        const x = (j % 2) * 2; // 0 or 2 inches
        const y = Math.floor(j / 2) * 3; // 0 or 3 inches

        pdf.addImage(imgData, "PNG", x, y, 2, 3);
      }

      // Add a new page unless it's the last one
      if (i < pages.length - 1) pdf.addPage();
    }

    // Save the PDF
    pdf.save("multi_labels.pdf");
  };
console.log("orders",orders)
  return (
    <div>
      {/* Orders List with Selection */}
      <div>
        <h3>Select Orders to Print Labels</h3>
        {orders.map((order, index) => (
          <div key={order.id} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedOrders.includes(order.id)}
              onChange={() => handleSelectOrder(order.id)}
              className="mr-2"
            />
            <label>{order.id} - {order.deliverTo}</label>
            {/* Hidden Label for Rendering */}
            <div
              ref={(el) => (labelRefs.current[order.id] = el)}
              className="hidden"
            >
              <div className="border p-2">
                <p><strong>Order ID:</strong> {order.orderId}</p>
                <p><strong>Amount:</strong> {order.total_amount}</p>
                {/* <p><strong>Address:</strong> {order.address}</p>
                <p><strong>Mobile:</strong> {order.mobile}</p>
                <p><strong>COD:</strong> ₹{order.codAmount}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Download Button */}
      <button
        className="btn btn-dark mt-3 px-5 mb-3 rounded hover:bg-blue-600"
        onClick={downloadMultipleLabels}
      >
        Print Selected Labels
      </button>
    </div>
  );
};

export default MultiLabelPrinter;
