import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Sidebar from '../Components/Sidebar';
import Navbarfg from '../Components/Navbar';

import Swal from 'sweetalert2';

import '../App.css';

function Contactus({property}) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

    const [blogId, setBlogId] = useState(null);
  const [properties, setProperties] = useState([]);
  const [bannerId, setBannerId] = useState(null);
  const [banners, setBanners] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isGift, setIsGift] = useState(false);

  // Handle file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("fff",selectedFile)
  };

  // Open modal on image click
  const handleImageClick = (e) => {
    const id = e.target.getAttribute('data-id'); // Get the banner ID from data-id attribute
    setBannerId(id); 
    setIsModalOpen(true);
  };

    // Open modal on image click
    const handleBannerClick = (e) => {
      setIsGift(false);

      setIsBannerOpen(true);
    };

    const handleGiftClick = (e) => {
      setIsBannerOpen(false);

      setIsGift(true);
    };
  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };
  // Handle form submission (Update button clicked)
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      alert('Please select a banner image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('firstBanner', selectedFile);
    try {
      const response = await fetch(`${apiUrl}home-banners/${bannerId}`, {
        method: 'PUT',
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        alert('Banner updated successfully!');
        closeModal();
        // You may want to add a way to refresh the image displayed after the update.
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      alert('Error updating banner');
    }
  };
  

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${apiUrl}banners`);
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties data:', error);
      }
    };

    const fetchProper = async () => {
      try {
        const response = await axios.get(`${apiUrl}giftbanners`, {
     // Pass categore as query parameter
        });

        setBanners(response.data);
      } catch (error) {
        console.error('Error fetching banners data:', error);
      }
    };
    fetchProper();

    fetchProperties();
  }, []);

  
  const [cate, setCate] = useState([]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchProper = async () => {
      try {
        const response = await axios.get(`${apiUrl}allcategory`);
        setCate(response.data);
      } catch (error) {
        console.error('Error fetching properties data:', error);
      }
    };

    fetchProper();
  }, []);
  
  const activeBanners = properties.filter((banner) => banner.status === '1');
  const mobileBanners = properties.filter((banner) => banner.status === '0');

    const handleDelete = async (propertyId) => {
          console.log("id",propertyId)
          // Show a confirmation dialog before deleting
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'This will delete the Banner and related entries!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
          });
      
          // If the user confirms, proceed with the deletion
          if (result.isConfirmed) {
            try {
              // Make the API request to delete the product
              await axios.delete(`${apiUrl}DeletGift/${propertyId}`);
      
              // Show success message
              Swal.fire(
                'Repace!',
                'The product has been deleted.',
                'success'
              );
            } catch (error) {
              // Handle error and show an error message
              console.error('Error deleting product:', error);
              Swal.fire(
                'Error!',
                'There was an issue deleting the product.',
                'error'
              );
            }
          }
        };

        const [image, setImage] = useState(null);
        const [category, setCategory] = useState('');
        const [subCategory, setSubCategory] = useState('');
        
        // const handleImageChange = (event) => {
        //   setImage(event.target.files[0]);
        // };

        const handleImageChange = (event) => {
          const file = event.target.files[0];
          if (!file) return;
        
          const fileType = file.type.split('/')[0];
        
          if (fileType === 'image') {
            setImage(file);
      
            console.log('Image file selected:', file.name);
            // Handle image processing here
          } else if (fileType === 'video') {
            setImage(file);
            console.log('Video file selected:', file.name);
            // Handle video processing here
          } else {
            console.error('Unsupported file type:', file.type);
          }
        };
      
        const handleCategoryChange = (event) => {
          setCategory(event.target.value);
      
        };
      
        const handleSubCategoryChange = (event) => {
          setSubCategory(event.target.value);
        };
      
   
        const handleSubmitagain = async (event) => {
          event.preventDefault();
        // Replace with your actual API URL
          console.log("category",image,category,subCategory)

          try {
            // Create a FormData object to handle file and text data
            const formData = new FormData();
            formData.append('image', image); // Append the image file
            formData.append('category', category); // Append the category
            formData.append('subCategory', subCategory); // Append the subcategory
            console.log("formData",formData)
            // Send the POST request
            const response = await axios.post(`${apiUrl}home-banners`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
        
        
      if (response.status === 201) {
        Swal.fire('Success', 'Data uploaded successfully', 'success');

      } else {
        Swal.fire('Error', 'Unexpected response from server', 'error');
      }
            setIsModalOpen(false);
            return response.data; // Return response data if needed
          } catch (error) {
            // Handle errors
            console.error('Error uploading product:', error.response?.data || error.message);
            throw error; // Rethrow the error to handle it in the calling code
          }
       
        };
        
      
        const handleSubmitgift = async (event) => {
          event.preventDefault();
        // Replace with your actual API URL

          try {
            // Create a FormData object to handle file and text data
            const formData = new FormData();
            formData.append('image', image); // Append the image file
            formData.append('category', category); // Append the category
            formData.append('subCategory', subCategory); // Append the subcategory
            console.log("formData",formData)
            // Send the POST request
            const response = await axios.post( `${apiUrl}gift-banners`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
        
        
      if (response.status === 201) {
        Swal.fire('Success', 'Data uploaded successfully', 'success');

      } else {
        Swal.fire('Error', 'Unexpected response from server', 'error');
      }
      setIsGift(false);
            return response.data; // Return response data if needed
          } catch (error) {
            // Handle errors
            console.error('Error uploading product:', error.response?.data || error.message);
            throw error; // Rethrow the error to handle it in the calling code
          }
       
        };
     
   
  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbarfg />
            <div className="container-fluid scrollable-content">
              <div className="container-fluid">
                <div className="mb-4">
                  <div className='propertys-div'>
                    <h1 className="h3 mb-0 text-white text-center pt-3">Home Banner</h1>
                    </div>
<div className='row'>
                    <div className='mt-5 col-md-3'>
                      <button className="btn btn-dark mt-3 px-5 mb-3"  onClick={handleBannerClick}>Add Home Banner</button>
                    </div>
                    <div className='mt-5 col-md-3'>
                      <button className="btn btn-dark mt-3 px-5 mb-3"  onClick={handleGiftClick}>Add Gifts Banner</button>
                    </div>

                    </div>
                    {isBannerOpen && (
                    <div className='mt-2'>
                    <div className="max-w-md mx-auto mt-10 p-5 border rounded shadow">
      <h1 className="text-xl font-bold mb-4">Upload Banner</h1>
      <form onSubmit={handleSubmitagain}>
      <div className="mb-4">
  <label className="block font-semibold mb-2">Upload Image or Video:</label>
  <input
    type="file"
    accept="image/*,video/*"
    onChange={handleImageChange}
    className="block w-full border p-2 rounded"
  />
</div>

        <div className="mb-4">
          <label className="block font-semibold mb-2">Category:</label>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="block w-full border p-2 rounded"
          >
            <option value="" disabled>Select Category</option>
            <option value="0">Mobile</option>
            <option value="1">Laptop</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-2">Subcategory:</label>
          <select
      className="form-select form-select-sm"
      aria-label=".form-select-sm example"
      style={{ width: 500 }}
      name="categore"
      value={subCategory}
      onChange={handleSubCategoryChange}
    >
      {/* Default Option */}
      <option value="" selected>
        Select Category
      </option>

      {/* Dynamically Map Categories */}
      {cate.map((categoryObj) => (
        <option key={categoryObj.id} value={categoryObj.categore}>
          {categoryObj.categore}
        </option>
      ))}
    </select>
      
        </div>

        <button
          type="submit"
          className="btn btn-dark mt-3 px-5 mb-3 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
                    </div>
                    )}

{isGift && (
                    <div className='mt-2'>
                    <div className="max-w-md mx-auto mt-10 p-5 border rounded shadow">
      <h1 className="text-xl font-bold mb-4">Upload Gift Banner</h1>
      <form onSubmit={handleSubmitgift}>
      <div className="mb-4">
  <label className="block font-semibold mb-2">Upload Image or Video:</label>
  <input
    type="file"
    accept="image/*,video/*"
    onChange={handleImageChange}
    className="block w-full border p-2 rounded"
  />
</div>

        <div className="mb-4">
          <label className="block font-semibold mb-2">Category:</label>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="block w-full border p-2 rounded"
          >
            <option value="" disabled>Select Category</option>
            <option value="0">Mobile</option>
            <option value="1">Laptop</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-2">Subcategory:</label>
          <select
      className="form-select form-select-sm"
      aria-label=".form-select-sm example"
      style={{ width: 500 }}
      name="categore"
      value={subCategory}
      onChange={handleSubCategoryChange}
    >
      {/* Default Option */}
      <option value="" selected>
        Select Category
      </option>

      {/* Dynamically Map Categories */}
      {cate.map((categoryObj) => (
        <option key={categoryObj.id} value={categoryObj.categore}>
          {categoryObj.categore}
        </option>
      ))}
    </select>
      
        </div>

        <button
          type="submit"
          className="btn btn-dark mt-3 px-5 mb-3 rounded hover:bg-blue-600"

        >
          Submit
        </button>
      </form>
    </div>
                    </div>
                    )}
<div className='row'>
                  <div className='mt-5 col-md-6'>
  <table id="example" className="table table-striped table-bordered table-responsive">
    <thead>
      <tr>
      <th className="bl5 text-nowrap" style={{ width: '500px' }}>Laptop BANNERS</th>
      
      </tr>
    </thead>
    <tbody>
      {activeBanners.map((property) => (
        <tr key={property.id}>
      <td className="text-nowrap">
        <img
          src={property.firstBanner}
          alt={property.feature_img}
          style={{ width: '500px', height: '200px', objectFit: 'cover' }}
          className="img-fluid"
          data-id={property.id} // Add custom data-id attribute
          onClick={handleImageClick}  // Click event to open modal
        />
      </td>

     
        </tr>
      ))}
    </tbody>
  </table>


                  </div>
                  <div className='mt-5 col-md-6'>
  <table id="example" className="table table-striped table-bordered table-responsive">
    <thead>
      <tr>
      <th className="bl5 text-nowrap" style={{ width: '500px' }}>Mobile BANNERS</th>
      
      </tr>
    </thead>
    <tbody>
      {mobileBanners.map((property) => (
        <tr key={property.id}>
      <td className="text-nowrap">
        <img
          src={property.firstBanner}
          alt={property.feature_img}
          style={{ width: '500px', height: '200px', objectFit: 'cover' }}
          className="img-fluid"
          data-id={property.id} // Add custom data-id attribute
          onClick={handleImageClick}  // Click event to open modal
        />
      </td>

     
        </tr>
      ))}
    </tbody>
  </table>


                  </div>
                  
                  </div>
                        {/* Modal for updating banner image */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Update Banner Image</h2>
            <form onSubmit={handleSubmit}>
              <input type="file" accept="image/*" onChange={handleFileChange} />

              <button type="submit">Update Banner</button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Optional: Modal styling */}
      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .modal-content h2 {
          margin-top: 0;
        }
        .modal-content button {
          margin: 10px;
        }
      `}</style>


                </div>



              </div>
            </div>

          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    </div>
  );
}

export default Contactus;
